<template>
  <v-row>
    <v-row id="reference" class="my-2">
      <v-col cols="12">
        <h3>Referensi</h3>
      </v-col>
      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Tanggal Mulai</div>
        <div v-if="borrowerData.borrowerReferences.startDate" class="pt-1">
          {{ convertDate(borrowerData.borrowerReferences.startDate) }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Nama Kantor</div>
        <div v-if="borrowerData.borrowerReferences.officeName" class="pt-1">
          {{ borrowerData.borrowerReferences.officeName }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Alamat Bisnis</div>
        <div
          v-if="borrowerData.borrowerReferences.businessAddress"
          class="pt-1"
        >
          {{
            borrowerData.borrowerReferences.businessAddress +
              ", " +
              borrowerData.borrowerReferences.businessVillage +
              ", " +
              borrowerData.borrowerReferences.businessSubdistrict +
              ", " +
              borrowerData.borrowerReferences.businessCity +
              ", " +
              borrowerData.borrowerReferences.businessProvince
          }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Status Kepemilikan Bisnis
        </div>
        <div
          v-if="borrowerData.borrowerReferences.businessOwnership.name"
          class="pt-1"
        >
          {{ borrowerData.borrowerReferences.businessOwnership.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import converters from "../../helpers/converters.js";

export default {
  props: {
    dataBorrowerMiddleware: Object,
  },

  computed: {
    borrowerData() {
      return this.dataBorrowerMiddleware;
    },
  },

  methods: {
    convertDate(date) {
      return converters.mediumDate(date);
    },
  },
};
</script>

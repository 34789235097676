<template>
  <v-row>
    <v-row id="photos" class="my-2">
      <v-col cols="12">
        <h3>Foto</h3>
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-medium text--secondary pb-1">Foto KTP</div>
        <v-card dark color="grey lighten-2" class="pa-8" elevation="0">
          <v-img
            class="mx-auto"
            :src="
              borrowerData.fotoKtp ||
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            "
            height="200"
            width="200"
            contain
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-medium text--secondary pb-1">Foto Selfie</div>
        <v-card dark color="grey lighten-2" class="pa-8" elevation="0">
          <v-img
            class="mx-auto"
            :src="
              borrowerData.fotoSelfie ||
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            "
            height="200"
            width="200"
            contain
          />
        </v-card>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  props: {
    dataBorrowerMiddleware: Object,
  },

  computed: {
    borrowerData() {
      return this.dataBorrowerMiddleware;
    },
  },
};
</script>

<template>
  <v-card class="pa-8">
    <div class="d-md-flex justify-space-between">
      <header-content :pageTitle="'Detail Borrower'" />
      <v-chip
        v-if="formNewBorrower.status === 'REJECTED'"
        color="red"
        label
        outlined
      >
        REJECTED
      </v-chip>
      <v-chip
        v-else-if="formNewBorrower.status === 'APPROVED'"
        color="green"
        label
        outlined
      >
        APPROVED
      </v-chip>
      <v-chip v-else color="yellow darken-3" label outlined>
        PENDING
      </v-chip>
    </div>

    <div class="pt-2 px-4">
      <grid-borrower-middleware :dataBorrowerMiddleware="formNewBorrower" />
      <grid-borrower-photo-middleware
        :dataBorrowerMiddleware="formNewBorrower"
      />
      <grid-borrower-reference-middleware
        :dataBorrowerMiddleware="formNewBorrower"
      />

      <v-divider class="my-8" />

      <div v-if="!formNewBorrower.status" class="d-flex justify-end">
        <v-btn color="primary" class="text-none" @click="approval(0)">
          Reject
        </v-btn>
        <div class="px-2" />
        <v-btn color="success" class="text-none" @click="approval(1)">
          Approve
        </v-btn>
      </div>

      <div v-else class="mx-auto">
        <div
          v-if="formNewBorrower.isVerified"
          class="text-center text--secondary"
        >
          This borrower has been reviewed
        </div>
        <div v-else class="text-center yellow--text text--darken-3">
          This borrower has not been reviewed
        </div>
      </div>
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import GridBorrowerMiddleware from "../../components/Grid/GridBorrowerMiddleware.vue";
import GridBorrowerPhotoMiddleware from "../../components/Grid/GridBorrowerPhotoMiddleware.vue";
import GridBorrowerReferenceMiddleware from "../../components/Grid/GridBorrowerReferenceMiddleware.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    PopupSuccess,
    PopupError,
    ButtonForm,
    GridBorrowerMiddleware,
    GridBorrowerPhotoMiddleware,
    GridBorrowerReferenceMiddleware,
  },

  data() {
    return {
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewBorrower: {},
      approvalPayload: {
        borrowerId: null,
        status: "",
      },
    };
  },

  async mounted() {
    this.fetchOneBorrower();
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-borrower-mw");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    async fetchOneBorrower() {
      await store
        .dispatch("starlord/fetchOneBorrowerMiddleware", this.$route.params.id)
        .then((r) => {
          this.formNewBorrower = r;
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    async approval(status) {
      this.loading = true;
      this.approvalPayload.borrowerId = Number(this.$route.params.id);
      if (status === 1) {
        this.approvalPayload.status = "APPROVED";
      } else {
        this.approvalPayload.status = "REJECTED";
      }
      try {
        console.log(this.approvalPayload);
        await store
          .dispatch("starlord/approvalBorrowerMiddleware", this.approvalPayload)
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log("error", err);
            this.loading = false;
          });
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
    convertDate(date) {
      return converters.mediumDate(date);
    },
  },
};
</script>

<template>
  <v-row>
    <v-row id="borrower-info" class="my-2">
      <v-col cols="12">
        <h3>Informasi Borrower</h3>
      </v-col>
      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Tipe Akun</div>
        <div
          v-if="borrowerData.borrowerType === 'PERSONAL_BORROWER'"
          class="pt-1"
        >
          Pribadi
        </div>
        <div v-else class="pt-1">Company</div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Nama Lengkap</div>
        <div v-if="borrowerData.fullName" class="pt-1">
          {{ borrowerData.fullName }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Kode Provider</div>
        <div v-if="borrowerData.productCode" class="pt-1">
          {{ borrowerData.productCode }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Nomor Telepon</div>
        <div v-if="borrowerData.phoneNumber" class="pt-1">
          {{ "+" + borrowerData.phoneNumber }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Email</div>
        <div v-if="borrowerData.email" class="pt-1">
          {{ borrowerData.email }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Nomor ID</div>
        <div v-if="borrowerData.idNumber" class="pt-1">
          {{ borrowerData.idNumber }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Nomor KK</div>
        <div v-if="borrowerData.familyCard" class="pt-1">
          {{ borrowerData.familyCard }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Tanggal Lahir</div>
        <div v-if="borrowerData.birthDate" class="pt-1">
          {{ convertDate(borrowerData.birthDate) }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Agama</div>
        <div v-if="borrowerData.religions.name" class="pt-1">
          {{ borrowerData.religions.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Pendidikan</div>
        <div v-if="borrowerData.educations.name" class="pt-1">
          {{ borrowerData.educations.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Status Perkawinan
        </div>
        <div v-if="borrowerData.maritals.name" class="pt-1">
          {{ borrowerData.maritals.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">Nama Gadis Ibu</div>
        <div v-if="borrowerData.motherMaidenName" class="pt-1">
          {{ borrowerData.motherMaidenName }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Nomor Telepon Darurat
        </div>
        <div v-if="borrowerData.emergencyContacts.contactNumber" class="pt-1">
          {{ "+" + borrowerData.emergencyContacts.contactNumber }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Nama Kontak Darurat
        </div>
        <div v-if="borrowerData.emergencyContacts.contactName" class="pt-1">
          {{ borrowerData.emergencyContacts.contactName }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Hubungan
        </div>
        <div
          v-if="borrowerData.emergencyContacts.relationships.name"
          class="pt-1"
        >
          {{ borrowerData.emergencyContacts.relationships.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Kepemilikan Rumah
        </div>
        <div
          v-if="borrowerData.borrowerReferences.homeOwnership.name"
          class="pt-1"
        >
          {{ borrowerData.borrowerReferences.homeOwnership.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Aset
        </div>
        <div v-if="borrowerData.assets.name" class="pt-1">
          {{ borrowerData.assets.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>
    </v-row>

    <v-row id="workingplace-info" class="my-2">
      <v-col cols="12">
        <h3>Informasi Tempat Kerja</h3>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Nama Kantor
        </div>
        <div v-if="borrowerData.borrowerReferences.officeName" class="pt-1">
          {{ borrowerData.borrowerReferences.officeName }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Tanggal Mulai
        </div>
        <div v-if="borrowerData.borrowerReferences.startDate" class="pt-1">
          {{ convertDate(borrowerData.borrowerReferences.startDate) }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Alamat
        </div>
        <div v-if="borrowerData.address" class="pt-1">
          {{ borrowerData.address }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Desa
        </div>
        <div v-if="borrowerData.village" class="pt-1">
          {{ borrowerData.village }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Kecamatan
        </div>
        <div v-if="borrowerData.subdistrict" class="pt-1">
          {{ borrowerData.subdistrict }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Kabupaten / Kota
        </div>
        <div v-if="borrowerData.city" class="pt-1">
          {{ borrowerData.city }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Provinsi
        </div>
        <div v-if="borrowerData.province" class="pt-1">
          {{ borrowerData.province }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Kode Pos
        </div>
        <div v-if="borrowerData.postalCode" class="pt-1">
          {{ borrowerData.postalCode }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Pekerjaan
        </div>
        <div v-if="borrowerData.jobs.name" class="pt-1">
          {{ borrowerData.jobs.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Tipe Pekerjaan
        </div>
        <div v-if="borrowerData.jobTypes.name" class="pt-1">
          {{ borrowerData.jobTypes.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Pekerjaan Online
        </div>
        <div v-if="borrowerData.jobOnlines.name" class="pt-1">
          {{ borrowerData.jobOnlines.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Pengalaman Kerja
        </div>
        <div v-if="borrowerData.workExperiences.name" class="pt-1">
          {{ borrowerData.workExperiences.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Pemasukan
        </div>
        <div v-if="borrowerData.incomes.name" class="pt-1">
          {{ borrowerData.incomes.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>
    </v-row>

    <v-row id="bank-info" class="my-2">
      <v-col cols="12">
        <h3>Informasi Bank</h3>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Nama Bank
        </div>
        <div v-if="borrowerData.borrowerBanks.banks.name" class="pt-1">
          {{ borrowerData.borrowerBanks.banks.name }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <div class="font-weight-medium text--secondary">
          Nomor Rekening Bank
        </div>
        <div v-if="borrowerData.borrowerBanks.bankAccountNumber" class="pt-1">
          {{ borrowerData.borrowerBanks.bankAccountNumber }}
        </div>
        <div v-else class="pt-1 font-weight-bold yellow--text text--darken-2">
          TIDAK ADA DATA
        </div>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import converters from "../../helpers/converters.js";

export default {
  props: {
    dataBorrowerMiddleware: Object,
  },

  computed: {
    borrowerData() {
      return this.dataBorrowerMiddleware;
    },
  },

  methods: {
    convertDate(date) {
      return converters.mediumDate(date);
    },
  },
};
</script>
